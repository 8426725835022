import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { IntlProvider } from "react-intl";
import { useSelector } from "react-redux";
import { translateFile as messages } from "./messages/index";

const ProviderLang = ({ children }) => {
  const lang = useSelector((state) => state.lang.locale);

  return (
    <IntlProvider
      key={lang}
      locale={lang}
      // defaultRichTextElements={{
      //   b: (chunks) => <b>{chunks}</b>,
      //   span: (chunks) => <sp>{chunks}</sp>,
      //   br: <br />,
      // }}
      defaultLocale="en-GB"
      messages={messages[lang]}
      // messages={flatten(messages[lang])}
      textComponent={Fragment}
    >
      {children}
    </IntlProvider>
  );
};

ProviderLang.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default ProviderLang;
