import React, { Fragment } from "react";
import PropTypes from "prop-types";
// import { BsArrowRight } from "react-icons/bs";
import * as Scroll from "react-scroll";
import { Link as LinkRouter } from "react-router-dom";

const BtnLink = ({ link, children, offset, ...rest }) => {
  let ScrollLink = Scroll.Link;

  const isLink = link.includes("/");
  const linkScroll = {
    spy: true,
    smooth: true,
    offset: offset === undefined ? 50 : offset,
    duration: 500,
    isDynamic: true,
  };

  return (
    <Fragment>
      {isLink ? (
        <LinkRouter to={link} {...rest}>
          {children}
        </LinkRouter>
      ) : (
        <ScrollLink to={link} {...linkScroll} {...rest}>
          {children}
        </ScrollLink>
      )}
    </Fragment>
  );
};

BtnLink.propTypes = {
  link: PropTypes.string.isRequired,
};

export default BtnLink;
