import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import ScrollToTop from "../components/shared/ScrollToTop";
import DefaultLayout from "../layouts/DefaultLayout";
import { routeList } from "./routeList";
import NotFound from "../pages/NotFound";

const route = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Switch>
        {routeList.map((item, idx) => (
          <DefaultLayout
            sensitive
            key={idx}
            path={item.path}
            exact
            component={item.component}
          />
        ))}
        <Route exact path="/404" component={NotFound} />
        <Redirect to="/404" />
      </Switch>
    </BrowserRouter>
  );
};

export default route;
