import React from "react";
import { useInput } from "../../hooks/index";

const Input = ({ ...rest }) => {
  const [value, setvalue] = useInput("");

  return <input value={value} onChange={setvalue} required {...rest} />;
};

export default Input;
