const initialState = {
  theme: "light",
  isDarkMode: false,
};

const darkModeReducer = (state = initialState, action) => {
  switch (action.type) {
    case "TOGGLE_SWITCH":
      const isDarkMode = !state.isDarkMode;
      let theme = isDarkMode ? "dark" : "light";

      document.documentElement.setAttribute("data-theme", theme);

      return { ...state, isDarkMode, theme };

    case "SET_THEME":
      let currentTheme = action.theme === "dark";
      let themeChange = currentTheme ? "dark" : "light";
      document.documentElement.setAttribute("data-theme", themeChange);

      return { ...state, isDarkMode: currentTheme, theme: themeChange };
    default:
      return state;
  }
};

export default darkModeReducer;
