import React from "react";
import BtnLink from "../../UI/BtnLink";
import NavMenu from "./NavMenu";

import { FormattedMessage } from "react-intl";

const FooterTop = () => {
  return (
    <div className="container max-w-screen-xl mx-auto px-4 ">
      <div className="footer--top sm:py-5 flexbox-center-c md:px-24">
        <h3 className="text-xl my-3 xss:text-2xl xs:text-3xl text-white text-center">
          <FormattedMessage
            id="footer.top.title"
            defaultMessage="Start boosting your business."
          />
        </h3>
        <BtnLink link="/" className="btn btn-lg my-3 btn--pulse">
          <FormattedMessage id="footer.top.btn" defaultMessage="Get started" />
        </BtnLink>
      </div>
      <NavMenu />
    </div>
  );
};

export default FooterTop;
