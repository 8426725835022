import React, { lazy } from "react";
import WrapSuspense from "../components/shared/WrapSuspense";
const LazyLoaded = lazy(() => import("../components/PrivacyPolicy/index"));

const PrivacyPolicy = () => {
  return (
    <WrapSuspense>
      <LazyLoaded />
    </WrapSuspense>
  );
};

export default PrivacyPolicy;
