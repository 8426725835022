import React from "react";
import LayoutContainer from "../LayoutContainer";
import ThemeLang from "./ThemeLang";
import Copyright from "./Copyright";
import Logo from "../Logo";

const FooterBottom = (props) => {
  return (
    <LayoutContainer
      section="footer--bottom py-1"
      container="max-w-screen-xl mx-auto p-4 md:px-24"
    >
      <div className="flexbox-between-c flex-col sm:flex-row px-4">
        <Logo theme="transparent" />
        <ThemeLang />
      </div>
      <Copyright />
    </LayoutContainer>
  );
};

export default FooterBottom;
