import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const Img = ({ src, className, children }) => {
  return <div className={clsx(src, className)}>{children}</div>;
};

Img.propTypes = {
  src: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default Img;
