import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const LayoutContainer = ({ section, container, children, id }) => {
  return (
    <section className={clsx(section)} id={clsx(id)}>
      <div className={clsx("container mx-auto", container)}>{children}</div>
    </section>
  );
};

LayoutContainer.propTypes = {
  id: PropTypes.string,
};

export default LayoutContainer;
