import React, { Fragment } from "react";
import PopoverContent from "./Popover/PopoverContent";
import { useDispatch, useSelector } from "react-redux";
import { localesCode } from "../../lang/constants";
import { useToggle } from "../../hooks/index";
import { Globe } from "../shared/icons";

const LangOptions = () => {
  const { isTrue, toggleValue, setFalse, setTrue } = useToggle(false);
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.lang.locale);

  const handleChange = (code) => {
    dispatch({ type: "SET_LANG", lang: code });
  };

  const variants = {
    expanded: {
      opacity: 1,
      transform: "translate(0, -10px)",
      transition: {
        when: "afterChildren",
        duration: 0.4,
      },
    },
    collapsed: {
      opacity: 0,
      transition: {
        ease: [0.83, 0, 0.17, 1],
        when: "afterChildren",
        duration: 0.4,
      },
    },
  };

  return (
    <div className="selectLang" onMouseLeave={setFalse}>
      <div className="popover">
        {isTrue && (
          <PopoverContent
            show={isTrue}
            open={setTrue}
            close={setFalse}
            variants={variants}
          >
            <Fragment>
              {localesCode.map(({ code, name }, idx) => {
                return (
                  <span
                    className={`btn selectLang--item  ${
                      lang === code ? "active" : ""
                    }`}
                    key={idx}
                    // onClick={() => handleChange(code)}
                    onClick={() => handleChange("en-GB")}
                  >
                    <i className={`img selectLang--item_${code}`}> </i>
                    {name}
                  </span>
                );
              })}
            </Fragment>
          </PopoverContent>
        )}
        <span onClick={toggleValue} className="selectLang--btnIcon">
          <Globe />
        </span>
      </div>
    </div>
  );
};

export default LangOptions;
