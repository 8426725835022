const initialState = {
  slide: true,
};

const signUpReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SWITCH-SLIDER":
      return { ...state, slide: !state.slide };

    default:
      return state;
  }
};

export default signUpReducer;
