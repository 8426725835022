import React from "react";
import { FormattedMessage } from "react-intl";

export const tel = [
  <FormattedMessage id="tel1" defaultMessage="+0400 666 224 411" />,
  <FormattedMessage id="tel2" defaultMessage="+0800 252 22 442" />,
  <FormattedMessage id="tel3" defaultMessage="+0500 353 222 44" />,
];

export const menuList = [
  {
    name: (
      <FormattedMessage id="footer.menu1.title" defaultMessage="DirectAds" />
    ),
    links: [
      <FormattedMessage id="footer.menu1.link.id1" defaultMessage="Home" />,
      <FormattedMessage
        id="footer.menu1.link.id2"
        defaultMessage="Publishers"
      />,
      <FormattedMessage
        id="footer.menu1.link.id3"
        defaultMessage="Advertisers"
      />,
      <FormattedMessage id="footer.menu1.link.id4" defaultMessage="Blog" />,
      <FormattedMessage id="footer.menu1.link.id5" defaultMessage="Careers" />,
      <FormattedMessage id="footer.menu1.link.id6" defaultMessage="Jobs" />,
      <FormattedMessage id="footer.menu1.link.id7" defaultMessage="Adgate" />,
    ],
  },
  {
    name: (
      <FormattedMessage id="footer.menu2.title" defaultMessage="Help center" />
    ),
    links: [
      <FormattedMessage
        id="footer.menu2.link.id1"
        defaultMessage="Any questions"
      />,
      <FormattedMessage id="footer.menu2.link.id2" defaultMessage="Service" />,
      <FormattedMessage
        id="footer.menu2.link.id3"
        defaultMessage="Dev Center"
      />,
      <FormattedMessage
        id="footer.menu2.link.id4"
        defaultMessage="Content Policies"
      />,
      <FormattedMessage
        id="footer.menu2.link.id5"
        defaultMessage="Trust Center"
      />,
      <FormattedMessage
        id="footer.menu2.link.id6"
        defaultMessage="Contact Us"
      />,
      <FormattedMessage id="footer.menu2.link.id7" defaultMessage="Plugins" />,
    ],
  },
  {
    name: (
      <FormattedMessage id="footer.menu3.title" defaultMessage="About us" />
    ),
    links: [
      <FormattedMessage
        id="footer.menu3.link.id1"
        defaultMessage="Our Story"
      />,
      <FormattedMessage id="footer.menu3.link.id2" defaultMessage="Careers" />,
      <FormattedMessage
        id="footer.menu3.link.id3"
        defaultMessage="Press Center"
      />,
      <FormattedMessage id="footer.menu3.link.id4" defaultMessage="Our Team" />,
      <FormattedMessage id="footer.menu3.link.id5" defaultMessage="Board" />,
      <FormattedMessage
        id="footer.menu3.link.id6"
        defaultMessage="Brand Guidelines"
      />,
      <FormattedMessage id="footer.menu3.link.id7" defaultMessage="Network" />,
    ],
  },
  {
    name: (
      <FormattedMessage id="footer.menu4.title" defaultMessage="Resources" />
    ),
    links: [
      <FormattedMessage
        id="footer.menu4.link.id1"
        defaultMessage="All Resource"
      />,
      <FormattedMessage
        id="footer.menu4.link.id2"
        defaultMessage="Case Studies"
      />,
      <FormattedMessage
        id="footer.menu4.link.id3"
        defaultMessage="Best Practices"
      />,
      <FormattedMessage
        id="footer.menu4.link.id4"
        defaultMessage="Recorded Webinars"
      />,
      <FormattedMessage id="footer.menu4.link.id5" defaultMessage="E-books" />,
      <FormattedMessage id="footer.menu4.link.id6" defaultMessage="Webinars" />,
      <FormattedMessage id="footer.menu4.link.id7" defaultMessage="Events" />,
    ],
  },
  {
    name: <FormattedMessage id="footer.menu5.title" defaultMessage="More" />,
    links: [
      <FormattedMessage id="footer.menu5.link.id1" defaultMessage="API" />,
      <FormattedMessage id="footer.menu5.link.id2" defaultMessage="Tags" />,
      <FormattedMessage id="footer.menu5.link.id3" defaultMessage="Network" />,
      <FormattedMessage id="footer.menu5.link.id4" defaultMessage="Specials" />,
      <FormattedMessage id="footer.menu5.link.id5" defaultMessage="Brands" />,
      <FormattedMessage
        id="footer.menu5.link.id6"
        defaultMessage="Auto Optimization"
      />,
    ],
  },
];

export const menuLinks = [
  {
    links: [
      "/",
      "/publishers",
      "/advertisers",
      "/about/blog",
      "/about/career",
      "/about/career/obs",
      "http://devfront.adgate.com/",
    ],
  },
  {
    links: [
      "/about/any-questions",
      "/resources/service",
      "Dev Center",
      "Content Policies",
      "Trust Center",
      "/contact",
      "plugins",
    ],
  },
  {
    links: [
      "/about/ourstory",
      "/about/career",
      "Press Center",
      "/about/our-team",
      "Board",
      "Brand Guidelines",
      "Network",
    ],
  },
  {
    links: [
      "All Resource",
      "Case Studies",
      "Best Practices",
      "Recorded Webinars",
      "E-books",
      "/advertisers/webinars",
      "/advertisers/events",
    ],
  },
  {
    links: [
      "API",
      "Tags",
      "Network",
      "Specials",
      "Brands",
      "/resources/tools/auto-optimization",
    ],
  },
];
