import React from "react";
import NavList from "./NavList";
import { menuList, menuLinks } from "./data";

const NavMenu = () => {
  return (
    <div className="menu my-3 sm:mb-6 grid gap-2 grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 ">
      {menuList.map(({ name, links }, idx) => (
        <NavList
          key={idx}
          id={`footer.menu.id${idx + 1}`}
          name={name}
          links={links}
          linksPath={menuLinks[idx]}
        />
      ))}
    </div>
  );
};

export default NavMenu;
