import React, { lazy } from "react";
import WrapSuspense from "../components/shared/WrapSuspense";
const LazyLoaded = lazy(() => import("../components/Events/index"));

const Events = () => {
  return (
    <WrapSuspense>
      <LazyLoaded />
    </WrapSuspense>
  );
};

export default Events;
