import React from "react";
import { FormattedMessage } from "react-intl";

const localesCode = [
  {
    code: "sq-AL",
    name: <FormattedMessage id="lang.sq-AL" defaultMessage="Albanian" />,
  },
  {
    code: "ar-AR",
    name: <FormattedMessage id="lang.ar-AR" defaultMessage="Arabic" />,
  },
  {
    code: "hr-HR",
    name: <FormattedMessage id="lang.hr-HR" defaultMessage="Croatian" />,
  },
  {
    code: "nl-NL",
    name: <FormattedMessage id="lang.nl-NL" defaultMessage="Dutsh" />,
  },
  {
    code: "en-GB",
    name: <FormattedMessage id="lang.en-GB" defaultMessage="English" />,
  },
  {
    code: "fr-FR",
    name: <FormattedMessage id="lang.fr-FR" defaultMessage="French" />,
  },
  {
    code: "de-DE",
    name: <FormattedMessage id="lang.de-DE" defaultMessage="German" />,
  },
  {
    code: "el-GR",
    name: <FormattedMessage id="lang.el-GR" defaultMessage="Greek" />,
  },
  {
    code: "it-IT",
    name: <FormattedMessage id="lang.it-IT" defaultMessage="Italian" />,
  },
  {
    code: "jp-JP",
    name: <FormattedMessage id="lang.jp-JP" defaultMessage="Japanese" />,
  },
  {
    code: "es-ES",
    name: <FormattedMessage id="lang.es-ES" defaultMessage="Spanish" />,
  },
  {
    code: "tr-TR",
    name: <FormattedMessage id="lang.tr-TR" defaultMessage="Turkish" />,
  },
];

export { localesCode };
