import React, { lazy } from "react";
import WrapSuspense from "../components/shared/WrapSuspense";
const LazyLoaded = lazy(() => import("../components/Lexicon/index"));

const Lexicon = () => {
  return (
    <WrapSuspense>
      <LazyLoaded />
    </WrapSuspense>
  );
};

export default Lexicon;
