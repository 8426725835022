import React from "react";
import "./styles/App.css";
import "./styles/hover-min.css";
import "./styles/main.scss";
import Routes from "./route/route";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { store, persistor } from "./store/store";
import Spinner from "./components/shared/Spinner";
import ProviderLang from "./lang/ProviderLang";

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={<Spinner />} persistor={persistor}>
        <ProviderLang>
          <Routes />
        </ProviderLang>
      </PersistGate>
    </Provider>
  );
}

export default App;
