import React, { Fragment, useEffect } from "react";
import { setTheme } from "../../store/actions/darkMode";
import { useSelector, useDispatch } from "react-redux";
import { HiOutlineMoon, HiSun } from "react-icons/hi";
import { useIntl } from "react-intl";
import Select from "../UI/Select";

const DarkMode = () => {
  const intl = useIntl();
  const isDarkMode = useSelector((state) => state.darkMode.isDarkMode);
  const dispatch = useDispatch();

  const darkTr = intl.formatMessage({
    id: "footer.bottom.dark",
    defaultMessage: "Dark theme",
  });
  const lightTr = intl.formatMessage({
    id: "footer.bottom.light",
    defaultMessage: "Light theme",
  });
  const themeCurrent = isDarkMode ? darkTr : lightTr;

  let isDark = window.matchMedia("(prefers-color-scheme: dark)").matches;

  useEffect(() => {
    let theme = isDark ? "dark" : "light";
    dispatch(setTheme(theme));
  }, [dispatch, isDark]);

  useEffect(() => {
    let theme = isDarkMode ? "dark" : "light";
    dispatch(setTheme(theme));
  }, [dispatch, isDarkMode]);

  const handleChange = (t) => {
    dispatch(setTheme(t));
  };

  return (
    <div className="darkMode mx-2">
      <span className="darkMode--icon">
        {isDarkMode ? (
          <HiOutlineMoon className="moon" />
        ) : (
          <HiSun className="sun" />
        )}
      </span>
      <Select defaultValue={themeCurrent}>
        <Fragment>
          <span className="option" onClick={() => handleChange("dark")}>
            {darkTr}
          </span>
          <span className="option" onClick={() => handleChange("light")}>
            {lightTr}
          </span>
        </Fragment>
      </Select>
    </div>
  );
};

export default DarkMode;
