import React from "react";
import { Route } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import Footer from "../components/shared/Footer/Footer";

const DefaultLayout = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(matchProps) => {
        return (
          <div className="page-landing">
            <div id="back-to-top-anchor"></div>
            <main className="main-container">
              <TransitionGroup>
                <CSSTransition
                  key={matchProps.location.key}
                  classNames="fade"
                  timeout={400}
                >
                  <Component {...matchProps} />
                </CSSTransition>
              </TransitionGroup>
            </main>
            <Footer />
          </div>
        );
      }}
    />
  );
};

export default DefaultLayout;
