import { combineReducers } from "redux";
import darkModeReducer from "./darkMode";
import langReducer from "./lang";
import signUpReducer from "./signUp";

const rootReducer = combineReducers({
  darkMode: darkModeReducer,
  lang: langReducer,
  signUp: signUpReducer,
});

export { rootReducer };
