import React, { Fragment } from "react";
import { BsPhone } from "react-icons/bs";
import LinkText from "../../UI/LinkText";
import { tel } from "./data";
import PopoverContent from "../../UI/Popover/PopoverContent";
import { useToggle } from "../../../hooks/index";

const PhoneNumber = () => {
  const { isTrue, toggleValue, setFalse, setTrue } = useToggle(false);
  const telNr = ["+383 44 224 411", "+0800 252 22 442", "+0500 353 222 44"];
  const telIcon = ["en-GB", "nl-NL", "de-DE"];
  const variants = {
    expanded: {
      opacity: 1,
      transform: "translate(0, -10px)",
      transition: {
        when: "afterChildren",
        duration: 0.4,
      },
    },
    collapsed: {
      opacity: 0,
      transition: {
        ease: [0.83, 0, 0.17, 1],
        when: "afterChildren",
        duration: 0.4,
      },
    },
  };
  return (
    <div className="selectNumber" onMouseLeave={setFalse}>
      <div className="popover">
        {isTrue && (
          <PopoverContent
            show={isTrue}
            open={setTrue}
            close={setFalse}
            variants={variants}
          >
            <Fragment>
              {telNr.map((item, idx) => {
                let telLink = item.replace(/\s/g, "");
                return (
                  <span key={idx} className="btn selectNumber--item">
                    <i className={`img selectNumber--item_${telIcon[idx]}`}></i>
                    <LinkText type="phone" link={telLink}>
                      {tel[idx]}
                    </LinkText>
                  </span>
                );
              })}
            </Fragment>
          </PopoverContent>
        )}
        <span onClick={toggleValue} className="selectNumber--btnIcon">
          <BsPhone />
        </span>
      </div>
    </div>
  );
};

export default PhoneNumber;
