export const translateFile = {
  "sq-AL": require("./sq-AL.json"),
  "ar-AR": require("./ar-AR.json"),
  "hr-HR": require("./hr-HR.json"),
  "nl-NL": require("./nl-NL.json"),
  "en-GB": require("./en-GB.json"),
  "fr-FR": require("./fr-FR.json"),
  "de-DE": require("./de-DE.json"),
  "el-GR": require("./el-GR.json"),
  "it-IT": require("./it-IT.json"),
  "jp-JP": require("./jp-JP.json"),
  "es-ES": require("./es-ES.json"),
  "tr-TR": require("./tr-TR.json"),
};
