import React from "react";
import { motion } from "framer-motion";
import PropTypes from "prop-types";

const PopoverContent = ({ show, open, close, variants, children }) => {
  // const variants = {
  //   expanded: {
  //     opacity: 1,
  //     transform: "translate(0, -10px)",
  //     transition: {
  //       when: "afterChildren",
  //       duration: 0.8,
  //     },
  //   },
  //   collapsed: {
  //     opacity: 0,
  //     transition: {
  //       ease: [0.83, 0, 0.17, 1],
  //       when: "afterChildren",
  //       duration: 0.8,
  //     },
  //   },
  // };

  return (
    <div
      className="popover--wrap--content"
      onMouseEnter={open}
      onMouseLeave={close}
    >
      <div className="popover--wrap--overlay">
        <motion.div
          layout
          initial="collapsed"
          animate={show ? "expanded" : "collapsed"}
          variants={variants}
          className="popover--content"
        >
          {children}
        </motion.div>
      </div>
    </div>
  );
};

PopoverContent.propTypes = {
  show: PropTypes.bool.isRequired,
  open: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  children: PropTypes.element,
};

export default PopoverContent;
