const initialState = {
  locale: "en-GB", // state qe e ruan ne inicializim
};

const langReducer = (state = initialState, action) => {
  switch (
    action.type /// i props si string te gjith actions
  ) {
    case "SET_LANG": // check this
      return { ...state, locale: action.lang }; // ja pason ktu state e vjeter nese ki psh vlera tjera mos me te hup,
    /// edhe action.lang eshte vlera kodit te gjuhes  qysh e ki definu ne actions ti e shenove code
    default:
      return state;
  }
};

export default langReducer;
