import React, { lazy } from "react";
import WrapSuspense from "../components/shared/WrapSuspense";
const LazyLoaded = lazy(() => import("../components/Login/index"));

const Login = () => {
  return (
    <WrapSuspense>
      <LazyLoaded />
    </WrapSuspense>
  );
};

export default Login;
