import React, { Suspense } from "react";
import Spinner from "./Spinner";
import PropTypes from "prop-types";

const WrapSuspense = ({ children }) => {
  return <Suspense fallback={<Spinner />}>{children}</Suspense>;
};

WrapSuspense.propTypes = {
  children: PropTypes.element.isRequired,
};

export default WrapSuspense;
