import React from "react";
import LinkText from "../../UI/LinkText";

const NavList = ({ name, links, linksPath }) => {
  return (
    <div className="menu--navlist my-3 ">
      <ul className="menu--navlist--ul ">
        <h4 className="title my-4 md:my-6">{name}</h4>
        {links.map((item, idx) => (
          <li key={idx} className="menu--navlist--ul--li my-1">
            <LinkText
              type="route"
              link={linksPath.links[idx]}
              // link="/"
              className="link menu--navlist--ul--li--a"
            >
              {item}
            </LinkText>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default NavList;
