import React from "react";
import LinkText from "../../UI/LinkText";
import { FormattedMessage } from "react-intl";

const Copyright = () => {
  return (
    <div className="footer--bottom--copyright xs:p-4 flexbox-between-c">
      <p className="copyright my-1">
        <FormattedMessage
          id="footer.bottom.copyright"
          defaultMessage="Copyright 2020. All rights are reserved. DirectADS."
        />
      </p>
      <p className="copyright-links my-1">
        <LinkText link="/about/privacy-policy" type="route" className="link">
          <FormattedMessage
            id="footer.bottom.privacy"
            defaultMessage="Privacy Policy"
          />
        </LinkText>
        {" - "}
        <LinkText link="/about/terms-of-use" type="route" className="link">
          <FormattedMessage
            id="footer.bottom.terms"
            defaultMessage="Terms & Conditions"
          />
        </LinkText>
      </p>
    </div>
  );
};

export default Copyright;
