import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Img from "../UI/Img";

const Logo = ({ theme }) => {
  return (
    <Link to="/" className="logo">
      <Img src={`logo--img_${theme}`} className="img logo--img" />
    </Link>
  );
};

Logo.propTypes = {
  theme: PropTypes.string.isRequired,
};

export default Logo;
