import React from "react";
import { useToggle } from "../../hooks/index";
import { motion } from "framer-motion";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";

const Select = ({ defaultValue, children }) => {
  const { isTrue, toggleValue, setFalse } = useToggle(false);

  const variants = {
    expanded: {
      opacity: 1,
      visibility: "visible",
      transform: "translate(0, 5px)",
      transition: {
        height: "auto",
        when: "afterChildren",
        duration: 0.3,
      },
    },
    collapsed: {
      transform: "translate(0, -10px)",
      opacity: 0,
      visibility: "hidden",
      transition: {
        height: "0",
        when: "afterChildren",
        duration: 0.3,
        ease: [0.83, 0, 0.17, 1],
      },
    },
  };

  return (
    <div className="select " data-isopen={isTrue} onMouseLeave={setFalse}>
      <div
        className="select--action flexbox-between-c flex-row"
        onClick={toggleValue}
      >
        <p>{defaultValue}</p>
        <span>{isTrue ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}</span>
      </div>
      {isTrue && (
        <div className="select--wrap--content">
          <div className="select--wrap--overlay">
            <motion.div
              layout
              initial="collapsed"
              animate={isTrue ? "expanded" : "collapsed"}
              variants={variants}
              className="select--content"
              onClick={toggleValue}
            >
              {children}
            </motion.div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Select;
