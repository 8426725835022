import Home from "../pages/Home";
import Faq from "../pages/Faq";
import OurTeam from "../pages/OurTeam";
import AdsFormat from "../pages/AdsFormat";
import ReferralProgram from "../pages/ReferralProgram";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import TermsOfUse from "../pages/TermsOfUse";
import HelpCenter from "../pages/HelpCenter";
import AutoOptimization from "../pages/AutoOptimization";
import WordpressWidget from "../pages/WordpressWidget";
import CSVMapper from "../pages/CSVMapper";
import rdParty from "../pages/3rdParty";
import Coupons from "../pages/Coupons";
import Affiliate from "../pages/Affiliate";
import PublisherArena from "../pages/PublisherArena";
import Tools from "../pages/Tools";
import Payments from "../pages/Payments";
import MobileApp from "../pages/MobileApp";
import SignUp from "../pages/SignUp";
import Login from "../pages/Login";
import Contact from "../pages/Contact";
import Events from "../pages/Events";
import Lexicon from "../pages/Lexicon";
import Service from "../pages/Service";
import Premium from "../pages/Premium";
import OurStory from "../pages/OurStory";
import Advertisers from "../pages/Advertisers";
import History from "../pages/History";
import About from "../pages/About";
import AnyQuestions from "../pages/AnyQuestions";
import LaOffice from "../pages/LaOffice";
import Offices from "../pages/Offices";
import SelfServing from "../pages/SelfServing";
import Leadership from "../pages/Leadership";
import Marketing from "../pages/Marketing";
import Location from "../pages/Location";
import Publishers from "../pages/Publishers";
import Career from "../pages/Career";
import Jobs from "../pages/Jobs";
import JobOpen from "../pages/JopOpen";
import Monetize from "../pages/Monetize";
import Webinars from "../pages/Webinars";
import Retargeting from "../pages/Retargeting";
import LastNews from "../pages/LastNews";
import Forecast from "../pages/Forecast";
import Seasonal from "../pages/Seasonal";
import Engineering from "../pages/Engineering";
import Trends from "../pages/Trends";
import Admarket from "../pages/Admarket";

const routeList = [
  { path: "/", component: Home },
  { path: "/contact", component: Contact },
  { path: "/signup", component: SignUp },
  { path: "/login", component: Login },
  { path: "/about", component: About },
  { path: "/about/faq", component: Faq },
  { path: "/about/our-team", component: OurTeam },
  { path: "/about/ourstory", component: OurStory },
  { path: "/about/leadership", component: Leadership },
  { path: "/about/privacy-policy", component: PrivacyPolicy },
  { path: "/about/terms-of-use", component: TermsOfUse },
  { path: "/about/location", component: Location },
  { path: "/about/career", component: Career },
  { path: "/about/lastnews", component: LastNews },
  { path: "/about/career/jobs", component: Jobs },
  { path: "/about/career/jobs/job-details", component: JobOpen },
  { path: "/about/history", component: History },
  { path: "/about/any-questions", component: AnyQuestions },
  { path: "/helpcenter", component: HelpCenter },
  { path: "/helpcenter/payments", component: Payments },
  { path: "/resources", component: Tools },
  { path: "/resources/service", component: Service },
  { path: "/publishers/marketing", component: Marketing },
  { path: "/publishers/admarket", component: Admarket },
  { path: "/resources/ads-format", component: AdsFormat },
  { path: "/resources/referral-program", component: ReferralProgram },
  { path: "/resources/tools/auto-optimization", component: AutoOptimization },
  { path: "/resources/tools/wordpress-widget", component: WordpressWidget },
  { path: "/resources/tools/csv-mapper", component: CSVMapper },
  { path: "/resources/tools/party-racking", component: rdParty },
  { path: "/resources/tools/affiliate-dashboard", component: Affiliate },
  { path: "/resources/tools/mobile-app", component: MobileApp },
  { path: "/resources/tools/monetize", component: Monetize },
  { path: "/resources/tools/forecast", component: Forecast },
  { path: "/resources/tools/seasonal", component: Seasonal },
  { path: "/resources/trends", component: Trends },
  { path: "/resources/tools/publisher-arena", component: PublisherArena },
  { path: "/advertisers", component: Advertisers },
  { path: "/advertisers/coupons", component: Coupons },
  { path: "/advertisers/events", component: Events },
  { path: "/advertisers/engineering-blog", component: Engineering },
  { path: "/advertisers/lexicon", component: Lexicon },
  { path: "/advertisers/offices/la-office", component: LaOffice },
  { path: "/advertisers/offices", component: Offices },
  { path: "/publishers/premium", component: Premium },
  { path: "/publishers", component: Publishers },
  { path: "/publishers/webinars", component: Webinars },
  { path: "/publishers/retargeting", component: Retargeting },
  { path: "/publishers/self-serve-platform", component: SelfServing },
];

export { routeList };
