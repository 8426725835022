import React from "react";
import PropTypes from "prop-types";
import { Link as LinkRouter } from "react-router-dom";
import { useSwitch } from "../../hooks";

const LinkText = ({ type, children, link, ...rest }) => {
  const cases = ["route", "email", "phone", "http"];
  const equalsCases = ["route", "mailto:", "tel:", ""];
  const [isCase] = useSwitch(cases, equalsCases, type);

  return isCase === "route" ? (
    <LinkRouter to={link} {...rest}>
      {children}
    </LinkRouter>
  ) : (
    <a href={isCase + link} rel="noopener" {...rest}>
      {children}
    </a>
  );
};

LinkText.propTypes = {
  type: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};

export default LinkText;
