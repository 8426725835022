import React from "react";
import LangOptions from "../../UI/LangOptions";
import DarkMode from "../DarkMode";
import PhoneNumber from "./PhoneNumber";

const ThemeLang = () => {
  return (
    <div className="flexbox-between-c flex-row my-3">
      <DarkMode />
      <div className="flexbox-between-c flex-row">
        <PhoneNumber />
        <LangOptions />
      </div>
    </div>
  );
};

export default ThemeLang;
