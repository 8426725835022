export const toggleSwitch = () => {
  return {
    type: "TOGGLE_SWITCH",
  };
};

export const setTheme = (theme) => {
  return {
    type: "SET_THEME",
    theme: theme,
  };
};
