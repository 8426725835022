import React from "react";
import { HiMail } from "react-icons/hi";
import Input from "../../UI/Input";
import { FormattedMessage, useIntl } from "react-intl";

const Subscribe = () => {
  const intl = useIntl();
  const placeholder = intl.formatMessage({
    id: "footer.top.input",
    defaultMessage: "Enter your email adress",
  });

  return (
    <div className="container max-w-screen-xl mx-auto px-6">
      <div className="flexbox-between-c flex-col sm:flex-row py-2 px-4  md:px-24">
        <h4 className="text-md text-center sm:text-left sm:my-3 xs:text-xl text-white">
          <FormattedMessage
            id="footer.top.desc"
            defaultMessage="Don't waste time, reach your goals"
          />
        </h4>
        <form
          action=""
          className="footer--subscribe w-full sm:w-5/12 my-4  mx-auto sm:mr-0"
        >
          <i className="icon">
            <HiMail />
          </i>
          <Input
            type="email"
            className="input footer--subscribe--input"
            placeholder={placeholder}
          />
          <button type="submit" className="btn footer--subscribe--btn ">
            <FormattedMessage
              id="footer.top.subscribe"
              defaultMessage="subscribe"
            />
          </button>
        </form>
      </div>
    </div>
  );
};

export default Subscribe;
